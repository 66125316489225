import axios from 'axios'

const config = {
    protocol: 'https:',
    apiUrl: 'api.freefone.app',
}

const http = axios.create({
    baseURL: config.protocol + '//' + config.apiUrl,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
})

export default http
