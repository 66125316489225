<template>
    <section class="section section-international-country">
        <div class="container">
            <div>
                <div class="section-title">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="underTitle">International</div>
                            <div class="title-h2 mb-56">Unlimited International to 80+ Destinations</div>
                        </div>
                    </div>
                </div>
                <div class="text-m-center text-t-center">
                    <div class="alphabetSwitch mb-32">
                        <span
                            v-for="letter in alphabet"
                            :key="letter"
                            :class="{ alphabetSwitchActive: currentCountryLetter === letter }"
                            style="font-family: 'Poppins'"
                            @click="switchCountry(letter)"
                            >{{ letter }}</span
                        >
                    </div>
                </div>
                <div :class="[currentCountryLetter == 'A' ? '' : 'd-none']">
                    <div class="table-responsive">
                        <table class="section-international-country-table mt-36">
                            <tr class="section-international-country-main-table-row">
                                <td>Country</td>
                                <td>Call Rate</td>
                                <td>SMS Rate</td>
                            </tr>
                            <tr
                                v-for="(country, index) in countryList"
                                :key="index"
                                class="section-international-country-table-row"
                            >
                                <td>{{ country.name }}</td>
                                <td>{{ country.minuteRate }}</td>
                                <td>{{ country.smsRate }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            currentCountryLetter: 'A',
            countryList: [
                {
                    name: 'Albania',
                    minuteRate: '10¢ /minute',
                    smsRate: '5¢ /sms',
                },
                {
                    name: 'Algeria',
                    minuteRate: '10¢ /minute',
                    smsRate: '5¢ /sms',
                },
                {
                    name: 'American Samoa',
                    minuteRate: '10¢ /minute',
                    smsRate: '5¢ /sms',
                },
                {
                    name: 'Andorra',
                    minuteRate: '10¢ /minute',
                    smsRate: '5¢ /sms',
                },
                {
                    name: 'Angola',
                    minuteRate: '10¢ /minute',
                    smsRate: '5¢ /sms',
                },
                {
                    name: 'Anguilla',
                    minuteRate: '10¢ /minute',
                    smsRate: '5¢ /sms',
                },
                {
                    name: 'Antigua',
                    minuteRate: '10¢ /minute',
                    smsRate: '5¢ /sms',
                },
                {
                    name: 'Argentina',
                    minuteRate: '10¢ /minute',
                    smsRate: '5¢ /sms',
                },
            ],
        }
    },
    computed: {
        alphabet() {
            return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
        },
    },
    methods: {
        switchCountry(countryValue) {
            this.currentCountryLetter = countryValue
        },
    },
}
</script>

<style>
@import './../../../assets/styles/components/sections/HomePage/08_CountryListSection.css';
</style>
