<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'

const getBlogPosts = async () => {
    try {
        const response = await axios.get(
            `https://www.googleapis.com/blogger/v3/blogs/${process.env.VUE_APP_BLOGGER_ID}/posts?key=${process.env.VUE_APP_GOOGLE_API_KEY}`
        )
        return response.data.items
    } catch (error) {
        console.error('Error fetching posts:', error)
        throw error
    }
}

const blogPosts = ref([])

onMounted(async () => {
    try {
        const posts = await getBlogPosts()
        blogPosts.value = posts
    } catch (error) {
        console.error('Error fetching blog posts:', error)
    }
})

const convertDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    const date = new Date(dateString)
    return date.toLocaleDateString(undefined, options)
}

const extractFirstImageLink = (html) => {
    const imageRegex = /<img.*?src="(.*?)"/
    const match = html.match(imageRegex)
    if (match && match[1]) {
        return match[1]
    } else {
        return null
    }
}
</script>

<template>
    <section class="container" style="max-width: 1200px; margin: 0 auto; padding: 64px 24px; padding-top: 120px">
        <div style="text-align: center; margin-bottom: 48px">
            <h2 style="font-size: 32px; font-weight: bold; color: #333">Our News & Blog</h2>
        </div>
        <div style="display: flex; flex-wrap: wrap; justify-content: center; gap: 32px">
            <div
                v-for="post in blogPosts"
                :key="post.id"
                style="
                    background: #fff;
                    border-radius: 8px;
                    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
                    overflow: hidden;
                    width: 30%;
                    min-width: 300px;
                    display: flex;
                    flex-direction: column;
                "
            >
                <img
                    style="width: 100%; height: 224px; object-fit: cover"
                    alt=""
                    :title="post.title"
                    :src="extractFirstImageLink(post.content)"
                />
                <div style="padding: 24px; display: flex; flex-direction: column; flex-grow: 1">
                    <router-link
                        :to="`/blog-details?index=${post.id}`"
                        style="font-size: 20px; font-weight: 600; color: #007bff; text-decoration: none"
                    >
                        {{ post.title }}
                    </router-link>
                    <p style="color: #666; margin-top: 8px; flex-grow: 1">{{ post.summary }}</p>
                    <div
                        style="
                            margin-top: 16px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            color: #666;
                            font-size: 14px;
                        "
                    >
                        <span
                            ><i class="fi fi-calendar" style="margin-right: 4px"></i>
                            {{ convertDate(post.published) }}</span
                        >
                        <router-link
                            :to="`/blog-details?index=${post.id}`"
                            style="color: #007bff; text-decoration: none; display: flex; align-items: center"
                        >
                            Read More <i class="fi fi-arrow-right" style="margin-left: 4px"></i>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
.group:hover .group-hover\:scale-105 {
    transform: scale(1.05);
}

.transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
}

.transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
}

.transition-colors {
    transition-property: color, background-color, border-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
}
</style>
