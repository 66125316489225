<template>
    <section class="hide-mobile hide-tablet" id="whatWeDoSection">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12 pr-59 pr-m-0">
                    <div class="underTitle">What We do</div>
                    <div class="title-h2 mb-32">The Perfect Solution for all identity theft protection</div>
                    <div class="mb-32">
                        <p>
                            Do you ever feel like giving out your number is too risky? Do you need a second number to
                            help you keep the various aspects of your life separate? Well, that’s what a Freefone phone
                            is for.
                        </p>
                    </div>
                    <div class="d-flex mb-32">
                        <div class="flex-grow-1">
                            <ul class="perfect-list">
                                <li>
                                    <img
                                        src="../../../assets/images/components/Sections/Perfect-list-01.svg"
                                        alt="Dating Sites"
                                    />
                                    Dating Sites
                                </li>
                                <li>
                                    <img
                                        src="../../../assets/images/components/Sections/Perfect-list-02.svg"
                                        alt="Social Media Sign up"
                                    />
                                    Social Media Sign up
                                </li>
                                <li>
                                    <img
                                        src="../../../assets/images/components/Sections/Perfect-list-03.svg"
                                        alt="Website sign ups"
                                    />
                                    Website sign ups
                                </li>
                            </ul>
                        </div>
                        <div class="flex-grow-1">
                            <ul class="perfect-list">
                                <li>
                                    <img
                                        src="../../../assets/images/components/Sections/Perfect-list-04.svg"
                                        alt="Ebay, Craiglist,"
                                    />
                                    eBay, Craiglist, etc.
                                </li>
                                <li>
                                    <img
                                        src="../../../assets/images/components/Sections/Perfect-list-05.svg"
                                        alt="Online Shopping"
                                    />
                                    Online Shopping
                                </li>
                                <li>
                                    <img
                                        src="../../../assets/images/components/Sections/Perfect-list-06.svg"
                                        alt="Online Survey"
                                    />
                                    Online Survey
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="text-m-center">
                        <a class="btn-indigo" href="https://portal.freefone.app"> Get Started </a>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12 text-center pl-59">
                    <img :src="imgage" class="img-fluid" alt="imagefluid" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            imgage: require('./../../../assets/images/components/Sections/Perfect.svg'),
        }
    },
}
</script>

<style>
@import './../../../assets/styles/components/sections/HomePage/05_PerfectSolutionSection.css';
</style>
