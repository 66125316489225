import { createRouter, createWebHistory } from 'vue-router'
import HomePage from './components/pages/HomePage'
import PrivacyPolicyPage from './components/pages/PrivacyPolicyPage'
import TermsofUsePage from './components/pages/TermsofUsePage'
import LawEnforcementForm from './components/pages/LawEnforcementForm'
import DeleteNumberPage from './components/pages/DeleteNumberPage'
import BlogPage from './components/pages/BlogPage.vue'

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage,
        alias: '/auth/:social?',
    },
    {
        path: '/terms-of-Use',
        component: TermsofUsePage,
    },
    {
        path: '/TermsofUsePage',
        redirect: '/terms-of-Use',
    },
    {
        path: '/privacy',
        component: PrivacyPolicyPage,
    },
    {
        path: '/law-enforcement-form',
        component: LawEnforcementForm,
    },
    {
        path: '/delete_number',
        component: DeleteNumberPage,
    },
    {
        path: '/blog',
        name: 'blog',
        component: BlogPage,
    },
    {
        path: '/blog-details',
        name: 'blogDetails',
        component: () => import('@/components/pages/BlogDetailsPage.vue'),
        props: (route) => ({ index: parseInt(route.query.index) }),
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        }
        return { top: 0 }
    },
})

export default router
